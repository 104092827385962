import React from 'react';
import Layout from '../components/Layout';
import CarSegments from '../components/CarSegments';
import StaticModels from '../components/StaticModels/StaticModels';
import CarFleetItem from '../components/CarFleetItem';

import towTruck from '../images/towTruck.png';
import powerIcon from '../images/power-icon.png';
import weightIcon from '../images/weight-icon.png';
import carFleetAll from '../images/fleetAll.png'



const ReplacementCar = ({data}) => {
    const staticDataSegment = StaticModels.staticDataSegment;
    const staticDataCar = StaticModels.staticCarData
    return (
        <Layout>
            <div className="img-wrapper xs-overflow-hidden">
                <div className="img-mask position-absolut w-100">
                    <div className="d-flex justify-content-center h-100">
                        <div className="d-flex align-items-center fs-2 xs-fs-15 w-50 xs-main-wrapper white-color xs-mt-1 text-align-center">
                        Wiemy, jak uciążliwe może być wypożyczenie samochodu zupełnie innego gabarytowo (i klasowo) do tego, które straciłeś w wyniku stłuczki czy wypadku. Dlatego w naszej ofercie znajdują się samochody ze wszystkich segmentów: A, B, C, D, E oraz F, żebyś otrzymał auto zastępcze idealnie dostosowane do Twoich potrzeb.
                        </div>
                    </div>
                </div>
                <img className="xs-width-auto" alt="" src={carFleetAll}></img>
            </div>
            <div className="main-wrapper">
                <p className="text-align-center">(liczba samochodów we flocie stale wzrasta, więc w razie potrzeby zadzwoń i zapytaj o aktualny stan!)</p>
                <div className="wrapper d-flex row flex-wrap mb-4">
                    {staticDataSegment.map((x, index) =>
                        <div className="d-flex col-3" key={`id-${index}`}>
                             <CarSegments title={x.title} desc={x.desc}></CarSegments>   
                        </div>
                    )}
                </div>
                <div className="d-flex row flex-wrap mb-4">
                    {staticDataCar.map((x, index) =>
                        <div className="d-flex col-3 mb-3" key={`fleet-${index}`}>
                            <CarFleetItem 
                            segment={x.segment}
                            year={x.year}
                            name={x.name}
                            capacity={x.capacity}
                            power={x.power}
                            fuel={x.fuel}
                            gearbox={x.gearbox}
                            type={x.type}
                            photo={x.photo}></CarFleetItem>
                        </div>    
                        )
                    }
                </div>
                <div className="d-flex flex-row justify-content-end xs-hidden">
                    <div className="d-flex pl-1 w-50">
                        <div className="d-flex flex-row align-items-end">
                            <div className="box green-bg py-05 px-2 mr-2">Pomoc Drogowa</div>
                            <div className="year">2007 r.</div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row">
                    <div className="d-flex flex-column w-50 pr-1 xs-pr-0">
                        <h3 className="green-color mt-2 mb-05">A do zadań specjalnych... </h3>
                        <p className="mt-0">Żeby móc pomagać Wam na drogach każdego dnia, potrzebny jest sprzęt do zadań specjalnych. Tak jak James Bond ma swojego Astona Martina, tak my możemy się pochwalić naszym Crafterem. Oprócz możliwości wyrwania na niego najlepszych dziewczyn, ratuje świat i wyciąga z opresji, pomagając nam przy holowaniu.
Jeśli potrzebujesz auta dostawczego, robisz przeprowadzkę czy przewozisz większy towar, zgłoś się do nas w celu umówienia warunków wypożyczenia.
</p>
                    </div>
                    <div className="d-flex w-50 flex-column pl-1 xs-pl-0">
                       
                                <div className="d-flex xs-only flex-row xs-justify-content-start xs-flex-row align-items-end">
                                    <div className="box green-bg py-05 px-2 mr-2">Pomoc Drogowa</div>
                                    <div className="year">2007 r.</div>
                                </div>
                       
                        <h2 className="green-color mb-05">Volkswagen Crafter</h2>
                        <div className="d-flex xs-only">
                            <div className="d-flex flex-row w-100">
                                    <div className="d-flex w-100 flex-column justify-content-center dark-bg white-color py-05 px-2 mr-4">
                                        Pomoc drogowa
                                    </div>
                                    <div className="d-flex w-100 flex-column justify-content-center dark-bg white-color py-05 px-2">
                                    5 miejsc siedzących + kierowca
                                    </div>
                                </div>
                        </div>
                        <div className="img-wrapper">
                            <div className="position-absolut w-100 type-car-box xs-hidden">
                                <div className="d-flex flex-row">
                                    <div className="d-flex w-30 xs-w-100 flex-column justify-content-center dark-bg white-color py-05 px-2 mr-4">
                                        <span className="text-align-center">Pomoc</span>
                                        <span className="text-align-center">drogowa</span>
                                    </div>
                                    <div className="d-flex w-30 flex-column justify-content-center dark-bg white-color py-05 px-2">
                                    <span className="text-align-center">5 miejsc siedzących </span>                                   
                                    <span className="text-align-center">+ kierowca</span>
                                    </div>
                                </div>
                            </div>
                            <img className="d-flex" alt="" src={towTruck}></img>
                        </div>
                        <div className="d-flex main-pattern-bg flex-row py-2">
                            <div className="d-flex flex-column xs-flex-row pl-4 xs-pl-2 xs-pr-2 w-35 xs-w-100 xs-justify-content-between">
                                <div className="mb-2 d-flex flex-row xs-flex-row align-items-center ">
                                    <img alt="" src={weightIcon}></img>
                                    <div className="d-flex white-color xs-fs-14  ml-2">1500 kg</div>
                                </div>
                                <div className="d-flex xs-mb-2 flex-row xs-flex-row align-items-center">
                                    <img alt="" src={powerIcon}></img>
                                    <div className="d-flex white-color xs-fs-14  ml-2">136 KM</div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="white-color d-flex xs-pl-2 flex-column xs-fs-14">
                                    <span>Wyposażenie:</span>
                                    <span>- wciągarka (12 ton)</span>
                                    <span>- oświetlenie (koguty, oświetlenie robocze)</span>
                                    <span>- rolki przystosowane do pracy z urwanym kołem</span> 
                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-color-background mt-4 py-4 white-color d-flex flex-column xs-px-2 px-12">
                    <div className="line__box d-flex mb-2">
                        <div className="line__dashed line__dashed--white"></div>
                    </div>
                    <h3 className="white-color font-normal">
                    Mamy dla Ciebie samochód zastępczy dopasowany do Twoich potrzeb.
Stale zwiększamy swoją flotę. Nie wiesz, czy mamy auto zastępcze z Twojego segmentu?
                    </h3>
                    <div className="mt-4 d-flex justify-content-center">
                    <a href="tel:+48570757300" className='btn btn-rounded green-bg main-color text-align-center fixed-size'>
                Zadzwoń
            </a>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ReplacementCar;