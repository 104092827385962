import panda from '../../images/panda.png';
import hunday from '../../images/hunday.png';
import citroen from '../../images/citroen.png';
import avensis from '../../images/avensis.png';
import audiA8 from '../../images/audiA8.png';
import volvoV70 from '../../images/volvo-v70.png';
import mockImg from '../../images/photoMock.png';

const StaticModels = {
    staticDataSegment: [{
        title: 'Segment A',
        desc: 'Zalicza się do niego samochody typowo miejskie, małe, o długości nadwozia około 3,5 m. i mocy silnika do 70 KM. Cena wypożyczenia jest niemal taka sama jak w przypadku klasy B.'
    },
    {
        title: 'Segment B',
        desc: 'Zalicza się do niego także samochody miejskie, ale takie, które stanowią namiastkę samochodów rodzinnych. Głównie z nadwoziem typu hatchback, a więc z praktyczniejszym bagażnikiem.'
    },
    {
        title: 'Segment C',
        desc: 'Typowa klasa średnia, samochody miejskie, którymi można też wyjechać w trasę. Zazwyczaj nadwozia typu hatchback albo kombi. Zapewniają komfort podróży dla 5 osób.'
    },
    {
        title: 'Segment D',
        desc: 'Segment samochodów z klasy średniej-wyższej. Są to samochody rodzinne, sedany lub kombi. Najlepiej sprawdzą się w nawet dłuższych trasach, ale nadadzą się także do jazdy po mieście.'
    },
    {
        title: 'Segment E',
        desc: 'W tym segmencie znajdują się samochody z wyższym litrażem, typowe auta długodystansowe. Są bardziej luksusowe, niż przedstawiciele poprzednich segmentów.'
    },
    {
        title: 'Segment F',
        desc: 'W tym segmencie znajdują się tylko limuzyny. Jest to najwyższa klasa osobowych samochodów luksusowych. Są najlepiej wyposażone i napędzane najmocniejszymi silnikami.'
    }],
    staticCarData: [{
        segment: 'Klasa A',
        name: 'Fiat Panda',
        year: '2008 r.',
        capacity: '1.1',
        power: '54 KM',
        fuel: 'benzyna',
        gearbox: 'manualna',
        type: null,
        photo: panda
    },{
        segment: 'Klasa C',
        name: 'Hyundai i30',
        year: '2019 r.',
        capacity: '1.4',
        power: '100 KM',
        fuel: 'benzyna',
        gearbox: 'manualna',
        type: null,
        photo: hunday
    },{
        segment: 'Klasa C',
        year: '2005 r.',
        name: 'Opel Astra',
        capacity: '1.7',
        power: '100 KM',
        fuel: 'benzyna',
        gearbox: 'manualna',
        type: 'Hatchback',
        photo: mockImg
    },{
        segment: 'Klasa C',
        year: '2010 r.',
        name: 'Peugeot Partner',
        capacity: '1.6',
        power: '90 KM',
        fuel: 'benzyna',
        gearbox: 'manualna',
        type: 'Dostawcze',
        photo: mockImg
    },{
        segment: 'Klasa C',
        year: '2010 r.',
        name: 'Citroen C4 Van',
        capacity: '1.6',
        power: '90 KM',
        fuel: 'benzyna',
        gearbox: 'manualna',
        type: null,
        photo: citroen
    },{
        segment: 'Klasa D',
        year: '2015 r.',
        name: 'Toyota Avensis',
        capacity: '1.8',
        power: '147 KM',
        fuel: 'benzyna',
        gearbox: 'manualna',
        type: 'Sedan',
        photo: avensis
    },{
        segment: 'Klasa E',
        year: '2008 r.',
        name: 'Volvo v70',
        capacity: '2.0',
        power: '136 KM',
        fuel: 'benzyna',
        gearbox: 'diesel',
        type: 'Kombi',
        photo: volvoV70
    },{
        segment: 'Klasa E',
        year: '2006 r.',
        name: 'Audi A6',
        capacity: '2.7',
        power: '>180 KM',
        fuel: 'benzyna',
        gearbox: 'manualna',
        type: 'Kombi',
        photo: mockImg
    },{
        segment: 'Klasa F',
        name: 'Audi A8',
        year: '2001 r.',
        capacity: '2.8',
        power: '193 KM',
        fuel: 'benzyna',
        gearbox: 'manualna',
        type: 'Sedan',
        photo: audiA8
    }]

}
    
export default StaticModels;

