import React from "react";
import capacityIcon from '../images/capacity-icon.png';
import powerIcon from '../images/power-icon.png';
import gearboxIcon from '../images/gearbox-icon.png';
import fuelIcon from '../images/fuel-icon.png';



class CarFleetItem extends React.Component {
    constructor(props){
        super(props);
    }
    
    renderType(type){
        if(type){
            return(
                <div className="position-absolut dark-bg white-color py-05 px-2 type-car-box">
                    {type}
                </div>
            )
        }
    }

    render(){
        return(
            <div className="d-flex flex-column w-100">
                <div className="d-flex flex-row xs-flex-row align-items-end">
                    <div className="box green-bg py-05 px-2 mr-2">{this.props.segment}</div>
                    <div className="year">{this.props.year}</div>
                </div>
                <h2 className="green-color">{this.props.name}</h2>
                <div className="img-wrapper">
                    {this.renderType(this.props.type)}
                    <img className="d-flex" alt="" src={this.props.photo}></img>
                </div>
                <div className="d-flex main-pattern-bg flex-column py-2">
                    <div className="d-flex flex-row xs-flex-row mb-2 xs-ml-2 ml-4">
                            <div className="d-flex flex-row xs-flex-row align-items-center w-50">
                                <img alt="" src={capacityIcon}></img>
                                <div className="d-flex xs-fs-14 white-color ml-2">{this.props.capacity}</div>
                            </div>
                            <div className="d-flex flex-row xs-flex-row align-items-center w-50">
                                <img alt="" src={gearboxIcon}></img>
                                <div className="d-flex xs-fs-14 white-color ml-2">{this.props.gearbox}</div>
                            </div>
                    </div>
                    <div className="d-flex flex-row xs-flex-row ml-4 xs-ml-2">
                        
                            <div className="d-flex flex-row xs-flex-row align-items-center w-50">
                                <img alt="" src={powerIcon}></img>
                                <div className="d-flex xs-fs-14 white-color ml-2">{this.props.power}</div>
                            </div>
                            <div className="d-flex flex-row xs-flex-row align-items-center w-50">
                                <img alt="" src={fuelIcon}></img>
                                <div className="d-flex xs-fs-14 white-color ml-2">{this.props.fuel}</div>
                            </div>
                        </div>
                    
                </div>
            </div>
        );
    }
}

export default CarFleetItem;