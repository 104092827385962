import React from "react";

class CarSegments extends React.Component{
    constructor(props){
        super(props);
    }


    render(){
        return (
            <div className="segments-wrapper">
                <h2 className="green-color mb-05">{this.props.title}</h2>
                <p className="mt-0">{this.props.desc}</p>
            </div>
        );
    }
}
export default CarSegments